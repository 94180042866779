/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'view-list': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 4.5h10a2 2 0 012 2v3a2 2 0 01-2 2H3a2 2 0 01-2-2v-3a2 2 0 012-2m0 1a1 1 0 00-1 1v3a1 1 0 001 1h10a1 1 0 001-1v-3a1 1 0 00-1-1zM1 2a.5.5 0 01.5-.5h13a.5.5 0 010 1h-13A.5.5 0 011 2m0 12a.5.5 0 01.5-.5h13a.5.5 0 010 1h-13A.5.5 0 011 14"/>',
    },
});
